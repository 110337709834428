import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			imagesForModal: []
		};
	},
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var regionId;
		let el = document.querySelector("[data-id]");
		if (el) {
			regionId = parseInt(el.getAttribute('data-id'), 10);
		}

		var unitGroupId = this.getUnitGroupId(regionId);


		var req = {
			fields: this.resultFields,
			filter: {
				unitgroupid: unitGroupId
			},
			sorting: 'random',
			max: 6
		};

		doReq('searchUnits', req)
			.then((res) => {
				//console.log(res.units);
				this.units = res.units;
			});

	},

	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();


	},
	methods: {
		getUnitGroupId: function (regionId) {
			var unitgroupid = "";
			if (regionId == 12831) {
				unitgroupid = 21918;
			} else if (regionId == 12768) {
				unitgroupid = 57194;
			} else if (regionId == 5312) {
				unitgroupid = 20035;
			}
			return unitgroupid;
		},
		getSearchPath: function (regionId) {
			var unitgroupid = this.getUnitGroupId(regionId);
			return "./s#?unitgroupid=" + unitgroupid;
		},
		getUnitSearchTarget: function (id) {
			return 'unit_' + id;
		},
	}

};